//PACKS
import React, { useEffect, useState } from "react";

//COMPONENTS
import AboutBox from "./AboutBox";

//STYLES
import "../styles/desktopAbout.scss";

//ASSETS
import { aboutList } from "../assets/json/aboutList";

export default function DesktopAbout() {
  //STATES
  const [colOne, setColOne] = useState(null);
  const [colTwo, setColTwo] = useState(null);

  //ON MOUNT
  useEffect(() => {
    setColOne(aboutList.slice(0, 3));
    setColTwo(aboutList.slice(3, 6));
  }, []);

  return (
    <div className="desktopAboutContainer">
      <div className="desktopAboutText">
        <h1>Software tailored </h1>
        <h1> to suit you</h1>
        <p>We design applications to </p>
        <p>perfectly fit your workflow</p>
      </div>
      <div className="desktopAboutGrid">
        <div className="desktopAboutCol">
          {colOne &&
            colOne.map((item, index) => (
              <AboutBox device="desktop" key={index} item={item} />
            ))}
        </div>
        <div className="desktopAboutCol">
          {colTwo &&
            colTwo.map((item, index) => (
              <AboutBox device="desktop" key={index} item={item} />
            ))}
        </div>
      </div>
    </div>
  );
}
