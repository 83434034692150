//PACKS
import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { AnimatePresence, motion } from "framer-motion";

//COMPONENTS
import PregoPredefined from "./PregoPredefined";
import PregoInput from "./PregoInput";

//STYLES
import "../styles/prego.scss";

export default function Prego(props) {
  const [display, setDisplay] = useState([]);
  const [data, setData] = useState({});
  const [question, setQuestion] = useState("");
  const [style, setStyle] = useState({});

  useEffect(() => {
    setStyle(props.style);
    handleAnswer(1);
  }, [props]);

  function handleData(ans) {
    if (ans) {
      let tempData = data;
      tempData[ans["q"]] = ans["a"];
      console.log(tempData);
      setData(tempData);
    }
  }

  function handleFinalDisplay() {
    setQuestion("");
  }

  function handleSendData() {
    emailjs
      .send(
        "service_u65xba6",
        props.prego[props.prego.length - 1],
        data,
        "user_eWfBfIiht2Qeg8scvIUju"
      )
      .then((r) => {
        setDisplay(
          <PregoPredefined
            handleAnswer={handleOk.bind(this)}
            data={{
              text: "Thank you! A confirmation mail is on its way",
            }}
            style={props.style.predefined}
          />
        );
      })
      .catch((e) => {
        setDisplay(
          <PregoPredefined
            handleAnswer={handleErr.bind(this)}
            data={{ text: "Something went wrong. Please try again!" }}
            style={props.style.predefined}
          />
        );
      });
  }

  function handleErr() {
    let temp = [];
    props.prego.forEach((p) => {
      if (p.id === 1) {
        setQuestion(p.question);
        p.answers.forEach((answer) => {
          switch (answer.type) {
            case "predefined":
              temp.push(
                <PregoPredefined
                  data={answer}
                  q={p.shorthand}
                  handleAnswer={handleAnswer.bind(this)}
                  style={props.style.predefined}
                />
              );
              break;
            case "input":
              temp.push(
                <PregoInput
                  data={answer}
                  q={p.shorthand}
                  handleAnswer={handleAnswer.bind(this)}
                  style={props.style.input}
                />
              );
          }
        });
        setDisplay(temp);
      }
    });
  }

  function handleOk() {
    console.log("Ok");
  }

  function handleAnswer(id, ans) {
    //push answer in data
    handleData(ans);
    //CLAW - prego finished
    if (!id) {
      handleSendData();
      handleFinalDisplay();
      return;
    }
    //set next question and answers
    let temp = [];
    props.prego.forEach((p) => {
      if (p.id === id) {
        setQuestion(p.question);
        p.answers.forEach((answer) => {
          switch (answer.type) {
            case "predefined":
              temp.push(
                <div key={p.shorthand + temp.length + 1}>
                  <PregoPredefined
                    data={answer}
                    q={p.shorthand}
                    handleAnswer={handleAnswer.bind(this)}
                    style={props.style.predefined}
                  />
                </div>
              );
              break;
            case "input":
              temp.push(
                <div key={p.shorthand + temp.length + 1}>
                  <PregoInput
                    data={answer}
                    q={p.shorthand}
                    handleAnswer={handleAnswer.bind(this)}
                    style={props.style.input}
                  />
                </div>
              );
          }
        });
        setDisplay(temp);
      }
    });
  }

  return (
    <div className="pregoContainer" style={style.container}>
      <AnimatePresence>
        {question && (
          <motion.p
            key={question}
            initial={{ opacity: 0, position: "absolute" }}
            animate={{ opacity: 1, position: "relative" }}
            exit={{ opacity: 0, position: "absolute" }}
            className="pregoQuestion"
            style={props.style.question}
          >
            {question}
          </motion.p>
        )}
      </AnimatePresence>
      <div className="pregoAnswers" style={props.style.answers}>
        {display}
      </div>
    </div>
  );
}
