import React, { useState, useEffect } from "react";
import TabletNavbar from "./TabletNavbar";
import TabletHero from "./TabletHero";
import TabletAbout from "./TabletAbout";
import TabletServices from "./TabletServices";
import TabletPortfolio from "./TabletPortfolio";
import TabletContact from "./TabletContact";
import TabletPrego from "./TabletPrego"

import "../styles/tablet.scss";
export default function Tablet(props) {
  //STATES
  const [width, setWidth] = useState(0);

  //ON PROP CHANGE
  useEffect(() => {
    setWidth(props.width);
  }, [props]);

  return (
    <div className="tabletContainer">
      <div className="tabletNavWhitespace" />
      <TabletNavbar />
      <TabletHero />
      <TabletAbout />
      <TabletServices width={width} />
      <TabletPortfolio width={width} />
      <TabletPrego />
      <TabletContact />
    </div>
  );
}
