//PACKS
import React, { useState } from "react";
import ScrollIntoView from "react-scroll-into-view";

//COMPONENTS
import Hamburger from "./Hamburger";

//STYLES
import "../styles/mobileNavbar.scss";

//ASSETS
import hamburger from "../assets/icons/hamburger.svg";

export default function MobileNavbar(props) {
  //STATE
  const [toggle, setToggle] = useState(false);

  function handleToggle() {
    setToggle(!toggle);
  }

  function renderHamburger() {
    if (props.device !== "desktop") {
      return (
        <Hamburger
          toggle={toggle}
          handleToggle={handleToggle.bind(this)}
          top="61px"
        />
      );
    }
  }

  return (
    <>
      <div className="mobileNavbarContainer">
        <ScrollIntoView selector="#hero">
          <p className="noselect">Matrix Software</p>
        </ScrollIntoView>
        <div className="navHamburgerContainer">
          <img
            className="navHamburger noselect"
            src={hamburger}
            alt="hamburger"
            onClick={() => {
              setToggle(!toggle);
            }}
          />
        </div>
      </div>
      {renderHamburger()}
    </>
  );
}
