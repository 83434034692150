import React from "react";
import "../styles/tabletHero.scss";

//ASSETS
import heroMobile from "../assets/images/heroMobile.png";

export default function TabletHero() {
  return (
    <div className="tabletHeroContainer">
      <div style={{ position: "absolute", top: "-113px" }} id="hero" />
      <div className="tabletHeroTitle fullwidth">
        <p>We listen to your</p>
        <p>digital needs</p>
      </div>
      <div className="tabletHeroBand ">
        <img src={heroMobile} alt="Matrix Software House creates mobile friendly websites" />
      </div>
      <div className="tabletHeroText">
        <p>Swipe and get to know us</p>
      </div>
    </div>
  );
}
