//MOCKUPS
import hicomockup from "../images/hicomockup.png";
import montecretomockup from "../images/montecretomockup.png";
import setmockup from "../images/setmockup.png";
import rtdmockup from "../images/rtdmockup.png";
import getcrowdmockup from "../images/getcrowdmockup.png";

//LOGOS
import LogoHico from "../images/LogoHico.png";
import LogoMontecreto from "../images/LogoMontecreto.png";
import LogoRtd from "../images/LogoRtd.png";
import LogoSet from "../images/LogoSet.png";
import LogoGetCrowd from "../images/LogoGetCrowd.png";

export const portfolioList = [
  {
    icon: LogoMontecreto,
    title: "Montecreto",
    description:
      "A quick digital tour around the activities and sights of the beautiful village of Montecreto",
    mockup: montecretomockup,
    link: "https://montecreto.eu",
  },
  {
    icon: LogoRtd,
    title: "Ready to digital",
    description:
      "A fast way to provide feedback to companies, specialised and optimized for every bussiness",
    mockup: rtdmockup,
    link: "https://www.readytodigital.it",
  },
  {
    icon: LogoSet,
    title: "SET",
    description:
      "Redesigned the existing website for SET, an important bussiness in the strategic advisory market",
    mockup: setmockup,
    link: "https://societaeconomiaterritorio.netlify.app/",
  },
  {
    icon: LogoHico,
    title: "Hico Recruiting",
    description:
      "Application that connects job seekers and companies through Hico Recruiting HR company",
    mockup: hicomockup,
    link: "https://www.hicorecruiting-fo.cf",
  },
  {
    icon: LogoGetCrowd,
    title: "Get Crowd",
    description:
      "Online tickets marketplace with a comprehensive management system for sellers",
    mockup: getcrowdmockup,
    link: "https://getcrowd.eu",
  },
];
