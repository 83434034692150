//PACKS
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//COMPONENTS
import SimpleBox from "./SimpleBox";

//STYLES
import "../styles/mobileContact.scss";

//ASSETS
import { contactList } from "../assets/json/contactList.js";

export default function MobileContact() {
  //STATES
  const [list, setList] = useState([]);

  //ON MOUNT
  useEffect(() => {
    setList(contactList);
  }, []);

  function copyToClipboard(text) {
    const notify = () =>
      toast.info("Copied to clipboard!", {
        position: toast.POSITION.TOP_CENTER,
        draggablePercent: 60,
        autoClose: 2000,
        icon: false,
        hideProgressBar: true,
      });
    if (text === "Matrix Software House") {
      navigator.clipboard.writeText(
        "https://www.linkedin.com/in/matrix-software-404170223/"
      );
      notify();
      return;
    }
    navigator.clipboard.writeText(text);
    notify();
  }

  return (
    <div className="mobileContactContainer">
      <div className="mobileContactTitle fullwidth">
        <p>You can</p>
        <p>reach us on</p>
      </div>
      <div className="mobileContactList">
        {list &&
          list.map((item, index) => (
            <div key={index} onClick={() => copyToClipboard(item.text)}>
              <SimpleBox data={item} />
            </div>
          ))}
        <ToastContainer limit={1} />
      </div>
      <div className="mobileContactRights">
        <p>© Matrix Software House 2021</p>
        <p>All rights reserved</p>
      </div>
    </div>
  );
}
