//PACKS
import React from "react";

//STYLES
import "../styles/mobileHero.scss";

//ASSETS
import heroMobile from "../assets/images/heroMobile.png";

export default function MobileHero() {
  return (
    <div className="mobileHeroContainer">
      <div style={{ position: "absolute", top: "-61px" }} id="hero" />
      <div className="mobileHeroTitle fullwidth">
        <p>We listen to your</p>
        <p>digital needs</p>
      </div>
      <div className="mobileHeroBand ">
        <img src={heroMobile} alt="Matrix Software House creates mobile friendly websites" />
      </div>
      <div className="mobileHeroText">
        <p>Swipe and get to know us</p>
      </div>
    </div>
  );
}
