//PACKS
import React, { useEffect, useState } from "react";

//COMPONENTS
import AboutBox from "./AboutBox";

//STYLES
import "../styles/tabletAbout.scss";

//ASSETS
import { aboutList } from "../assets/json/aboutList";

export default function TabletAbout() {
  //STATES
  const [colOne, setColOne] = useState(null);
  const [colTwo, setColTwo] = useState(null);
  const [colThree, setColThree] = useState(null);

  //ON MOUNT
  useEffect(() => {
    setColOne(aboutList.slice(0, 2));
    setColTwo(aboutList.slice(2, 4));
    setColThree(aboutList.slice(4, 6));
  }, []);

  return (
    <div className="TabletAboutContainer">
      <div style={{ position: "absolute", top: "-113px" }} id="about" />
      <div className="TabletAboutTitle fullwidth">
        <p>Software tailored to suit you</p>
      </div>
      <div className="TabletAboutSubtitle fullwidth">
        <p>We design applications to perfectly fit your workflow</p>
      </div>
      <div className="TabletAboutGrid fullwidth">
        <div className="TabletAboutCol">
          {colOne &&
            colOne.map((item, index) => (
              <AboutBox device="tablet" key={index} item={item} />
            ))}
        </div>
        <div className="TabletAboutCol">
          {colTwo &&
            colTwo.map((item, index) => (
              <AboutBox device="tablet" key={index} item={item} />
            ))}
        </div>
        <div className="TabletAboutCol">
          {colThree &&
            colThree.map((item, index) => (
              <AboutBox device="tablet" key={index} item={item} />
            ))}
        </div>
      </div>
    </div>
  );
}
