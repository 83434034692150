//PACKS
import React, { useEffect, useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";

//COMPONENTS
import Mobile from "./components/Mobile";
import Desktop from "./components/Desktop";
import Tablet from "./components/Tablet";

import "./fonts/FuturaMedium.ttf";
import "./fonts/FuturaHeavy.ttf";

import "./App.scss";

function App() {
  //HOOKS
  const [device, setDevice] = useState("");
  const windowWidth = useWindowWidth();

  //ON WINDOW WIDTH CHANGE
  useEffect(() => {
    if (windowWidth >= 768 && windowWidth < 1025) {
      setDevice(<Tablet width={windowWidth} />);
    } else if (windowWidth >= 1024) {
      setDevice(<Desktop />);
    } else {
      setDevice(<Mobile width={windowWidth} />);
    }
  }, [windowWidth]);

  return <div className="App">{device}</div>;
}

export default App;
