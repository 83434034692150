//LOGOS
import LogoXD from "../images/LogoXD.png";
import LogoReact from "../images/LogoReact.png";
import LogoROR from "../images/LogoROR.png";
import LogoDO from "../images/LogoDO.png";

export const aboutList = [
  {
    type: "secondary",
    name: "Everything",
  },
  {
    type: "primary",
    name: "Stunning UI",
    icon: LogoXD,
  },
  {
    type: "primary",
    name: "Responsive client",
    icon: LogoReact,
  },
  {
    type: "primary",
    name: "Flexible Server",
    icon: LogoROR,
  },
  {
    type: "primary",
    name: "Fast Hosting",
    icon: LogoDO,
  },
  {
    type: "secondary",
    name: "And more",
  },
];
