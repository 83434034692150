//PACKS
import React from "react";

//STYLES
import "../styles/whiteSpace.scss";

export default function WhiteSpace() {
  return (
    <div className="whiteSpace">
    </div>
  );
}
