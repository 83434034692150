//PACKS
import React, { useState, useEffect } from "react";

//STYLES
import "../styles/simpleBox.scss";

export default function SimpleBox(props) {
  //STATES
  const [data, setData] = useState(null);

  //ON PROPS CHANGE
  useEffect(() => {
    setData(props.data);
  }, [props]);

  return (
    <div className="simpleBoxContainer">
      {data ? (
        <>
          <img src={data.icon} alt="icon" />
          <p>{data.text}</p>
        </>
      ) : null}
    </div>
  );
}
