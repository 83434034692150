//PACKS
import React, { useState, useEffect } from "react";

//STYLES
import "../styles/servicesCard.scss";

export default function ServicesCard(props) {
  //STATES
  const [service, setService] = useState(null);

  //ON PROPS CHANGE
  useEffect(() => {
    setService(props.service);
  }, [props]);

  return service ? (
    <div
      className="servicesCard"
      onClick={() => {
        props.handleModal(service);
      }}
    >
      <div className="servicesTitle">
        <h1>{service.title}</h1>
        <img src={service.icon} alt="Matrix Software creates software such as ecommerce, CRM, CMS and more" />
      </div>
      <div className="servicesImg">
        <img src={service.mockup} alt="Matrix Software creates software such as ecommerce, CRM, CMS and more" />
      </div>
      <div className="servicesDescription">
        <p>{service.description}</p>
      </div>
    </div>
  ) : (
    <></>
  );
}
