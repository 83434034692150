//PACKS
import React, { useState, useEffect } from "react";

//COMPONENTS
import CarouselMulti from "./CarouselMulti";
import PortfolioCard from "./PortfolioCard";

//STYLES
import "../styles/desktopPortfolio.scss";

//ASSETS
import { portfolioList } from "../assets/json/portfolioList";

export default function DesktopPortfolio() {
  //STATES
  const [cards, setCards] = useState([]);

  //ON MOUNT
  useEffect(() => {
    let tempCards = [];
    portfolioList.forEach((service, index) => {
      tempCards.push(<PortfolioCard service={service} key={index} />);
    });
    setCards(tempCards);
  }, []);

  return (
    <div className="desktopPortfolioContainer">
      <div className="desktopPortfolioText">
        <h1>We take pride in our work</h1>
        <p>Yours could be next</p>
      </div>
      <CarouselMulti cards={cards} />
    </div>
  );
}
