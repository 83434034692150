//PACKS
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

//STYLES
import "../styles/servicesModal.scss";

//COMPONENTS
import Prego from "./Prego";

export default function ServicesModal(props) {
  const [isVisible, setVisible] = useState(false);
  const [prego, setPrego] = useState(false);
  useEffect(() => {
    function handleVisible(value) {
      setVisible(value);
    }
    handleVisible(true);
    return () => {
      handleVisible(false);
    };
  }, [props]);

  return (
    <AnimatePresence>
      {isVisible && (
        <div className="servicesModalContainer">
          <motion.div
            key={props.service.title + "bg"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="servicesModalBacground"
          />
          <motion.div
            key={props.service.title}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="servicesModal"
          >
            <div className="servicesTitle">
              <h1>{props.service.title}</h1>
              <p
                onClick={() => {
                  props.handleModal(null);
                }}
              >
                Cancel
              </p>
            </div>
            <div className="servicesContent">
              <div className="servicesImg">
                <img src={props.service.mockup} alt="modalMockup" />
              </div>
              <AnimatePresence>
                {prego && (
                  <motion.div
                    key={"prego" + prego}
                    initial={{ opacity: 0, y: -50, position: "absolute" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50, position: "absolute" }}
                    className="servicesPregoContent"
                  >
                    <Prego prego={props.service.prego} style={props.style} />
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {!prego && (
                  <motion.div
                    key={"removable" + prego}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 100 }}
                    className="servicesRemovableContent"
                  >
                    <button
                      className="servicesModalButton"
                      onClick={() => {
                        setPrego(true);
                      }}
                    >
                      Build my own
                    </button>
                    <div className="servicesModalSeparator">
                      <div />
                      <p>or keep reading</p>
                    </div>
                    <p className="servicesParagraph">
                      {props.service.paragraph1}
                    </p>
                    <div className="servicesImg">
                      <img src={props.service.mockup} alt="modalMockup" />
                    </div>
                    <p className="servicesParagraph">
                      {props.service.paragraph2}
                    </p>
                    <button className="servicesModalButton">
                      Build my own
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}
