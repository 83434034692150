import linkedin from "../icons/linkedin.svg";
import phone from "../icons/phone.svg";
import mail from "../icons/mail.svg";

export const contactList = [
  {
    icon: mail,
    text: "contact@matrixsoftwarehouse.com",
  },
  {
    icon: phone,
    text: "+40766563674",
  },
  {
    icon: linkedin,
    text: "Matrix Software House",
  },
];
