//PACKS
import React, { useState, useEffect } from "react";

//COMPONENTS
import ServicesCard from "./ServicesCard";
import ServicesModal from "./ServicesModal";

//STYLES
import "../styles/desktopServices.scss";

//ASSETS
import { servicesList } from "../assets/json/servicesList";
const style = {
  container: {
    width: "514px",
  },
  question: {
    textAlign: "center",
    marginTop: "30px",
    width: "514px",
    fontSize: "20px",
  },
  answers: {
    marginTop: "20px",
    width: "514px",
    display: "flex",
    flexDirection: "column",
  },
  predefined: {
    width: "514px",
  },
  input: {
    width: "514px",
    input: "504",
  },
};
export default function DesktopServices(props) {
  //STATES
  const [cards, setCards] = useState([]);
  const [modal, setModal] = useState(null);

  //ON MOUNT
  useEffect(() => {
    let tempCards = [];
    servicesList.forEach((service, index) => {
      tempCards.push(
        <ServicesCard
          service={service}
          handleModal={handleModal.bind(this)}
          key={index}
        />
      );
    });
    setCards(tempCards);
  }, []);

  //ON CARD/MODAL CLICK
  function handleModal(service) {
    handleBackgroundScroll(service);
    setModal(service);
  }

  function handleBackgroundScroll(service) {
    if (service === null) {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = "hidden";
    }
  }

  return (
    <div className="desktopServicesContainer">
      {modal ? (
        <ServicesModal
          style={style}
          handleModal={handleModal.bind(this)}
          service={modal}
        />
      ) : (
        <></>
      )}
      <div className="desktopServicesText">
        <h1>What’s your next move?</h1>
        <p>We got you covered</p>
      </div>
      <div className="desktopServicesGrid">{cards}</div>
    </div>
  );
}
