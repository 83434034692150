import React from "react";
import "../styles/hamburger.scss";
import { motion } from "framer-motion";
import ScrollIntoView from "react-scroll-into-view";

export default function Hamburger(props) {
  const animation = {
    close: { opacity: 0, transform: "translatey(-4em)" },
    open: { opacity: 1, transform: "translatey(0)" },
  };
  return (
    <motion.div
      variants={animation}
      animate={props.toggle ? "open" : "close"}
      className="hamburgerContainer"
    >
      <ScrollIntoView
        onClick={() => {
          props.handleToggle();
        }}
        selector="#about"
      >
        <li className="noselect">About</li>
      </ScrollIntoView>
      <ScrollIntoView
        onClick={() => {
          props.handleToggle();
        }}
        selector="#services"
      >
        <li className="noselect">Services</li>
      </ScrollIntoView>
      <ScrollIntoView
        onClick={() => {
          props.handleToggle();
        }}
        selector="#portfolio"
      >
        <li className="noselect">Portfolio</li>
      </ScrollIntoView>
      <ScrollIntoView
        onClick={() => {
          props.handleToggle();
        }}
        selector="#prego"
      >
        <li className="noselect">Contact us</li>
      </ScrollIntoView>
    </motion.div>
  );
}
