//PACKS
import React, { useState, useEffect } from "react";

//STYLES
import "../styles/portfolioCard.scss";

export default function ServicesCard(props) {
  //STATES
  const [service, setService] = useState(null);

  //ON PROPS CHANGE
  useEffect(() => {
    setService(props.service);
  }, [props]);

  return service ? (
    <div className="portfolioCardContainer">
      <a
        href={service.link}
        target="_blank"
        rel="noreferrer"
        className="portfolioCardLink"
      >
        <div className="portfolioCard">
          <div className="portfolioCardShade" />
          <div className="portfolioCardTop">
            <img src={service.icon} alt="Matrix Software creates software such as ecommerce, CRM, CMS and more" />

            <h1>{service.title}</h1>
          </div>
          <p>{service.description}</p>
          <div className="portfolioCardMockup">
            <img src={service.mockup} alt="Matrix Software creates software such as ecommerce, CRM, CMS and more" />
          </div>
        </div>
      </a>
    </div>
  ) : (
    <></>
  );
}
