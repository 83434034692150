export const prego = [
  {
    id: 1,
    question: "           ",
    shorthand: "reason",
    answers: [
      {
        type: "predefined",
        text: "I want you to develop an app for me",
        next: 2,
      },
      {
        type: "predefined",
        text: "I want a partnership with you",
        next: 3,
      },
    ],
  },
  {
    id: 2,
    question: "What is the current state of the app?",
    shorthand: "state",
    answers: [
      {
        type: "predefined",
        text: "It needs to be done from scratch",
        next: 5,
      },
      {
        type: "predefined",
        text: "It's already in development",
        next: 6,
      },
    ],
  },
  {
    id: 3,
    question: "What is the purpose of the app?",
    shorthand: "purpose",
    answers: [
      {
        type: "predefined",
        text: "Management",
        next: 5,
      },
      {
        type: "predefined",
        text: "Online shopping",
        next: 5,
      },
    ],
  },
  {
    id: 4,
    question: "How do you want to be involved with us?",
    shorthand: "reason",
    answers: [
      {
        type: "predefined",
        text: "Financially",
        next: 8,
      },
      {
        type: "predefined",
        text: "Technically",
        next: 5,
      },
      {
        type: "predefined",
        text: "Both",
        next: 5,
      },
      {
        type: "input",
        text: "Other...",
        next: 5,
      },
    ],
  },
  {
    id: 5,
    question: "Can you tell us more about the app?",
    shorthand: "app",
    answers: [
      {
        type: "input",
        text: "Type here...",
        next: 7,
      },
      {
        type: "predefined",
        text: "I would rather not",
        next: 7,
      },
    ],
  },
  {
    id: 6,
    question: "Can you provide is with a link to your app?",
    shorthand: "link",
    answers: [
      {
        type: "input",
        text: "Type here...",
        next: 4,
      },
      {
        type: "predefined",
        text: "I cannot",
        next: 4,
      },
    ],
  },
  {
    id: 7,
    question: "Can we get your name?",
    shorthand: "name",
    answers: [
      {
        type: "input",
        text: "Type here...",
        next: 8,
      },
      {
        type: "predefined",
        text: "I would rather not disclose my name",
        next: 8,
      },
    ],
  },
  {
    id: 8,
    question: "Can we get a contact email?",
    shorthand: "email",
    answers: [
      {
        type: "input",
        text: "Type here...",
        next: null,
      },
    ],
  },
  "template_nrq5uof",
];

export const onlineShoppingPrego = [
  {
    id: 1,
    question: "Do you have an already established brand identity?",
    shorthand: "has_brand_identity",
    answers: [
      { type: "predefined", text: "Yes", next: 2 },
      { type: "predefined", text: "No", next: 4 },
    ],
  },
  {
    id: 2,
    question: "Do you already have an e-commerce platform of your own?",
    shorthand: "has_ecommerce",
    answers: [
      { type: "predefined", text: "Yes", next: 3 },
      { type: "predefined", text: "No", next: 5 },
    ],
  },
  {
    id: 3,
    question: "What kind of items do you sell on your ecommerce website?",
    shorthand: "sold_items",
    answers: [
      { type: "input", text: "Type here...", next: 6 },
      {
        type: "predefined",
        text: "I would rather not disclose that yet",
        next: 6,
      },
    ],
  },
  {
    id: 4,
    question:
      "Would you like us to also create a brand indentity for your business?",
    shorthand: "wants_brand_identity",
    answers: [
      { type: "predefined", text: "Yes", next: 5 },
      { type: "predefined", text: "No", next: 5 },
    ],
  },
  {
    id: 5,
    question: "What do you plan on selling on your new platform?",
    shorthand: "wants_to_sell",
    answers: [
      { type: "input", text: "Type here...", next: 6 },
      {
        type: "predefined",
        text: "I would rather not disclose that yet",
        next: 6,
      },
    ],
  },
  {
    id: 6,
    question: "Could we get your name for future discussions?",
    shorthand: "name",
    answers: [
      { type: "input", text: "Type your name here...", next: 7 },
      {
        type: "predefined",
        text: "I would rather not disclose my name",
        next: 7,
      },
    ],
  },
  {
    id: 7,
    question: "Could you provide an email adress?",
    shorthand: "email",
    answers: [{ type: "input", text: "Type your email here...", next: null }],
  },
  "template_nrq5uof",
];

export const managementSystemPrego = [
  {
    id: 1,
    question: "Do you manage your business online?",
    shorthand: "manage_business_online",
    answers: [
      { type: "predefined", text: "Yes", next: 2 },
      { type: "predefined", text: "No", next: 3 },
    ],
  },
  {
    id: 2,
    question: "What software do you use to manage your business?",
    shorthand: "software_for_management",
    answers: [{ type: "input", text: "Type here...", next: 3 }],
  },
  {
    id: 3,
    question:
      "We can create a tailored management system for your domain of activity",
    shorthand: "info_management",
    answers: [
      {
        type: "predefined",
        text: "Next",
        next: 4,
      },
    ],
  },
  {
    id: 4,
    question: "What does your business do?",
    shorthand: "business_activity",
    answers: [
      { type: "input", text: "Type here...", next: 5 },
      {
        type: "predefined",
        text: "I would rather not disclose this yet",
        next: 5,
      },
    ],
  },
  {
    id: 5,
    question: "Could we get your name for future discussions?",
    shorthand: "name",
    answers: [
      { type: "input", text: "Type your name here...", next: 6 },
      {
        type: "predefined",
        text: "I would rather not disclose my name",
        next: 6,
      },
    ],
  },
  {
    id: 6,
    question: "Could you provide an email adress?",
    shorthand: "email",
    answers: [{ type: "input", text: "Type your email here...", next: null }],
  },
  "template_nrq5uof",
];

export const landingPagePrego = [
  {
    id: 1,
    question: "What do you need a landing page for?",
    shorthand: "reason_landing_page",
    answers: [
      { type: "predefined", text: "My business", next: 2 },
      { type: "input", text: "Other", next: 3 },
    ],
  },
  {
    id: 2,
    question: "What does your business do?",
    shorthand: "business_activity",
    answers: [
      { type: "input", text: "Type here...", next: 4 },
      {
        type: "predefined",
        text: "I would rather not disclose this yet",
        next: 4,
      },
    ],
  },
  {
    id: 3,
    question: "What do you need to showcase on the page?",
    shorthand: "info_page",
    answers: [
      { type: "input", text: "Type here...", next: 4 },
      {
        type: "predefined",
        text: "I would rather not disclose this yet",
        next: 4,
      },
    ],
  },
  {
    id: 4,
    question: "Do you already have a landing page?",
    shorthand: "already_have_page",
    answers: [
      { type: "predefined", text: "Yes", next: 5 },
      { type: "predefined", text: "No", next: 6 },
    ],
  },
  {
    id: 5,
    question: "Can you provide a link for it?",
    shorthand: "info_page",
    answers: [
      { type: "input", text: "Type here...", next: 6 },
      { type: "predefined", text: "I don't have one yet", next: 6 },
    ],
  },
  {
    id: 6,
    question: "Could we get your name for future discussions?",
    shorthand: "name",
    answers: [
      { type: "input", text: "Type your name here...", next: 7 },
      {
        type: "predefined",
        text: "I would rather not disclose my name",
        next: 7,
      },
    ],
  },
  {
    id: 7,
    question: "Could you provide an email adress?",
    shorthand: "email",
    answers: [{ type: "input", text: "Type your email here...", next: null }],
  },
  "template_nrq5uof",
];

export const entertainmentAppPrego = [
  {
    id: 1,
    question: "Do you sell content?",
    shorthand: "does_sell_content",
    answers: [
      { type: "predefined", text: "Yes", next: 2 },
      { type: "predefined", text: "No", next: 3 },
    ],
  },
  {
    id: 2,
    question: "What kind of content are you selling?",
    shorthand: "selling_content",
    answers: [
      { type: "input", text: "Type here...", next: 4 },
      {
        type: "predefined",
        text: "I would rather not disclose this yet",
        next: 4,
      },
    ],
  },
  {
    id: 3,
    question: "What kind of content would you like to sell?",
    shorthand: "info_content",
    answers: [
      { type: "input", text: "Type here...", next: 4 },
      {
        type: "predefined",
        text: "I would rather not disclose this yet",
        next: 4,
      },
    ],
  },
  {
    id: 4,
    question: "Are you creating the content?",
    shorthand: "creation_of_content",
    answers: [
      { type: "predefined", text: "Yes", next: 5 },
      { type: "predefined", text: "No", next: 7 },
    ],
  },
  {
    id: 5,
    question: "Could we get your name for future discussions?",
    shorthand: "name",
    answers: [
      { type: "input", text: "Type your name here...", next: 6 },
      {
        type: "predefined",
        text: "I would rather not disclose my name",
        next: 6,
      },
    ],
  },
  {
    id: 6,
    question: "Could you provide an email adress?",
    shorthand: "email",
    answers: [{ type: "input", text: "Type your email here...", next: null }],
  },
  {
    id: 7,
    question: "Are you in charge of the content?",
    shorthand: "charge_of_content",
    answers: [
      { type: "predefined", text: "Yes", next: 5 },
      { type: "predefined", text: "No", next: 8 },
    ],
  },
  {
    id: 8,
    question: "Can we get the name of the person in charge?",
    shorthand: "person_in_charge",
    answers: [
      { type: "predefined", text: "Yes", next: 6 },
      { type: "predefined", text: "No", next: 6 },
    ],
  },
  "template_nrq5uof",
];
