//PACKS
import React, { useState, useEffect } from "react";

//STYLES
import "../styles/portfolioCardTablet.scss";

export default function PortfolioCardTablet(props) {
  //STATES
  const [service, setService] = useState(null);

  //ON PROPS CHANGE
  useEffect(() => {
    setService(props.service);
  }, [props]);

  return service ? (
    <div className="portfolioCardTabletContainer box">
      <a
        href={service.link}
        target="_blank"
        rel="noreferrer"
        className="portfolioCardLink"
      >
        <div className="portfolioCardTabletText">
          <img src={service.icon} alt="Matrix Software creates software such as ecommerce, CRM, CMS and more" />
          <h1>{service.title}</h1>
        </div>
        <p>{service.description}</p>
        <div className="portfolioCardTabletMockup">
          <img src={service.mockup} alt="Matrix Software creates software such as ecommerce, CRM, CMS and more" />
        </div>
      </a>
    </div>
  ) : (
    <></>
  );
}
