//PACKS
import React from "react";

//COMPONENTS
import Prego from "./Prego";

//STYLES
import "../styles/tabletPrego.scss";

//ASSETS
import { prego } from "../assets/json/pregoList";

export default function TabletPrego() {
  return (
    <div className="tabletPregoContainer">
      <div style={{ position: "absolute", top: "-113px" }} id="prego" />
      <div className="tabletPregoText">
        <h1>Have something in mind? Prego!</h1>
      </div>
      <Prego prego={prego} style="" />
    </div>
  );
}
