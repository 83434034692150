//PACKS
import React from "react";

//STYLES
import "../styles/desktopContact.scss";

//ASSETS
import { contactList } from "../assets/json/contactList.js";

export default function DesktopContact() {
  return (
    <div className="desktopContactContainer">
      <div className="desktopContactMethods">
        {contactList &&
          contactList.map((item, index) => (
            <div key={index}>
              <img src={item.icon} alt="contactIcon" />
              <p>{item.text}</p>
            </div>
          ))}
      </div>
      <div className="desktopContactLegal">
        <p>© Matrix Software House 2021</p>
        <p>All rights reserved</p>
      </div>
    </div>
  );
}
