//PACKS
import React from "react";

//ASSETS
import caretRight from "../assets/icons/caretRight.svg";
import caretLeft from "../assets/icons/caretLeft.svg";

export default function CarouselButtons(props) {
  return (
    <div className="carouselButtons">
      <button
        onClick={() => {
          props.handleButtons(1);
        }}
      >
        <img src={caretLeft} alt="caretLeft" />
      </button>
      <button
        onClick={() => {
          props.handleButtons(-1);
        }}
      >
        <img src={caretRight} alt="caretRight" />
      </button>
    </div>
  );
}
