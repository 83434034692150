//PACKS
import React from "react";

//COMPONENTS
import WhiteSpace from "./WhiteSpace";
import DesktopHero from "./DesktopHero";
import DesktopAbout from "./DesktopAbout";
import DesktopServices from "./DesktopServices";
import DesktopPortfolio from "./DesktopPortfolio";
import DesktopPrego from "./DesktopPrego";
import DesktopContact from "./DesktopContact";

//STYLES
import "../styles/desktop.scss";

export default function Desktop() {
  return (
    <div className="desktopContainer">
      <DesktopHero />
      <WhiteSpace />
      <DesktopAbout />
      <DesktopServices />
      <DesktopPortfolio />
      <DesktopPrego />
      <DesktopContact />
    </div>
  );
}
