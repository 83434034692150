//PACKS
import React, { useState, useEffect } from "react";

//STYLES
import "../styles/aboutBox.scss";

const styles = [
  {
    type: "desktop",
    whites: {
      width: "150px",
      height: "150px",
    },
    blacks: {
      backgroundColor: "#262626",
      color: "white",
      width: "150px",
      height: "150px",
    },
  },
  {
    type: "tablet",
    whites: {
      width: "198px",
      height: "150px",
    },
    blacks: {
      backgroundColor: "#262626",
      color: "white",
      width: "198px",
      height: "150px",
    },
  },
  {
    type: "mobile",
    whites: {
      width: "150px",
      height: "150px",
    },
    blacks: {
      backgroundColor: "#262626",
      color: "white",
      width: "150px",
      height: "75px",
    },
  },
];

export default function AboutBox(props) {
  //STATES
  const [whites, setWhites] = useState({});
  const [blacks, setBlacks] = useState({});
  const [boxType, setBoxType] = useState(null);

  //ON PROP CHANGE
  useEffect(() => {
    styles.forEach((style) => {
      if (style.type === props.device) {
        setWhites(style.whites);
        setBlacks(style.blacks);
      }
    });
    setBoxType(props.item.type);
  }, [props]);

  return boxType === "primary" ? (
    <div className="aboutBoxContainer" style={whites}>
      <img src={props.item.icon} alt="We are creating responsive applications using React JS and Ruby on Rails" />
      <p style={{ marginTop: "15px" }}>{props.item.name}</p>
    </div>
  ) : (
    <div className="aboutBoxContainer" style={blacks}>
      <p>{props.item.name}</p>
    </div>
  );
}
