//PACKS
import React, { useState, useEffect } from "react";

//COMPONENTS
import CarouselBleeding from "./CarouselBleeding";
import ServicesCard from "./ServicesCard";
import ServicesModal from "./ServicesModal";

//STYLES
import "../styles/tabletServices.scss";

//ASSETS
import { servicesList } from "../assets/json/servicesList";

const style = {
  container: {
    width: "514px",
  },
  question: {
    textAlign: "center",
    marginTop: "30px",
    width: "514px",
    fontSize: "20px",
  },
  answers: {
    marginTop: "20px",
    width: "514px",
  },
  predefined: {
    width: "514px",
  },
  input: {
    width: "514px",
    input: "464",
  },
};

export default function TabletServices(props) {
  //STATES
  const [cards, setCards] = useState([]);
  const [width, setWidth] = useState(0);
  const [modal, setModal] = useState(null);

  //ON MOUNT
  useEffect(() => {
    let tempCards = [];
    servicesList.forEach((service, index) => {
      tempCards.push(
        <ServicesCard
          service={service}
          handleModal={handleModal.bind(this)}
          key={index}
        />
        // <div
        //   style={{
        //     backgroundColor: "black",
        //     height: "400px",
        //     width: "634px",
        //     margin: "0 calc((100vw - 634px)/8)",
        //   }}
        // ></div>
      );
    });
    setCards(tempCards);
  }, []);

  //ON PROPS CHANGEG
  useEffect(() => {
    setWidth(props.width);
  }, [props]);

  function handleModal(service) {
    handleBackgroundScroll(service);
    setModal(service);
  }

  function handleBackgroundScroll(service) {
    if (service === null) {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = "hidden";
    }
  }
  return (
    <div className="tabletServicesContainer">
      <div style={{ position: "absolute", top: "-113px" }} id="services" />
      {modal ? (
        <ServicesModal
          style={style}
          handleModal={handleModal.bind(this)}
          service={modal}
        />
      ) : (
        <></>
      )}
      <div className="tabletServicesTitle fullwidth">
        <p>What’s your next move?</p>
      </div>
      <div className="tabletServicesSubtitle fullwidth">
        <p>We got you covered</p>
      </div>
      <CarouselBleeding
        cards={cards}
        width={width}
        cardHeight={470}
        cardWidth={634 + (width - 634) / 4}
      />
    </div>
  );
}
