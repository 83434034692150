//PACKS
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

//STYLES
import "../styles/carouselBleeding.scss";

export default function CarouselBleeding(props) {
  //STATES
  const [cards, setCards] = useState(null);
  const [length, setLength] = useState(0);
  const [height, setHeight] = useState(375);
  const [cardWidth, setCardWidth] = useState(0);
  const [position, setPosition] = useState(0);

  //LOAD ITEMS
  useEffect(() => {
    setCards(props.cards);
    setLength(props.cards.length);
    setHeight(props.cardHeight);
    setCardWidth(props.cardWidth);
    setPosition((props.width - props.cardWidth) / 2);
  }, [props]);

  function resetPos() {
    let pos = position;
    setPosition(false);
    setPosition(pos);
  }

  //SETS DIRECTION BASED ON OFFSET GIVEN BY DRAGGABLE
  function handleOnDragEnd(offset) {
    //INPUT
    let x = offset.x;
    let y = offset.y;

    if (Math.abs(y) < 100) {
      //MATH
      // console.log("START----------");
      let direction = Math.sign(x);
      // console.log("direction", direction);
      let times = Math.floor(Math.abs(x) / cardWidth);
      let rest = Math.abs(x) % cardWidth;
      if (rest > 0.4 * cardWidth) {
        times++;
      }
      //MOVEMENT
      if (times === 0) {
        resetPos();
      }
      if (direction === -1) {
        let nextPos = position - cardWidth * times;
        // console.log("nextPos", nextPos);
        if (Math.abs(nextPos) > cardWidth * (length - 1)) {
        } else {
          setPosition(nextPos);
        }
      } else if (direction === 1) {
        let nextPos = position + cardWidth * times;
        // console.log("nextPos", nextPos);
        if (nextPos <= 0) {
          setPosition(nextPos);
        } else {
        }
      }
    } else {
      resetPos();
    }
    // console.log("----------STOP");
  }

  return (
    <div
      className="carouselBleedingContainer"
      style={{ height: height + "px" }}
    >
      <motion.div
        className="carousel"
        dragConstraints={{
          right: (props.width - props.cardWidth) / 2,
          left: -cardWidth * (length - 1),
        }}
        drag="x"
        animate={{ x: position }}
        onDragEnd={(event, info) => handleOnDragEnd(info.offset)}
      >
        {cards}
      </motion.div>
    </div>
  );
}
