//PACKS
import React from "react";

//COMPONENTS
import Prego from "./Prego";

//STYLES
import "../styles/desktopPrego.scss";

//ASSETS
import { prego } from "../assets/json/pregoList";

export default function DesktopPrego() {
  return (
    <div className="desktopPregoContainer">
      <div className="desktopPregoText">
        <h1>Have something in mind? Prego!</h1>
      </div>
      <Prego prego={prego} style="" />
    </div>
  );
}
