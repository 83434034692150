//PACKS
import React from "react";

//STYLES
import "../styles/desktopHero.scss";

//ASSETS
import HeroImg from "../assets/images/heroDesktop.png";

export default function DesktopHero() {
  return (
    <div className="desktopHeroContainer">
      <div className="desktopHero">
        <div className="desktopHeroText">
          <div>
            <h1>We listen to your </h1>
            <h1>digital needs</h1>
            <p>You focus on what you love while we</p>
            <p>take care of your digital world</p>
          </div>
          <div>
            <h1>Matrix turns your</h1>
            <h1>vision into reality</h1>
            <p>You bring the idea</p>
            <p>we bring the solution</p>
          </div>
        </div>
        <div className="desktopHeroImg">
          <img src={HeroImg} alt="Matrix Software House creates mobile friendly websites" />
        </div>
      </div>
    </div>
  );
}
