//ICONS
import shoppingBag from "../icons/shoppingBag.svg";
import lightbulb from "../icons/lightbulb.svg";
import cardholder from "../icons/cardholder.svg";
import entertainment from "../icons/entertainment.svg";

//PICS
import onlineshopping from "../images/onlineshopping1.png";
import managementsystem from "../images/managementsystem1.png";
import landingpage from "../images/landingpage1.png";
import entertainmentapp from "../images/entertainmentapp1.png";

//PREGOS
import { onlineShoppingPrego } from "./pregoList";
import { managementSystemPrego } from "./pregoList";
import { entertainmentAppPrego } from "./pregoList";
import { landingPagePrego } from "./pregoList";

export const servicesList = [
  {
    icon: shoppingBag,
    title: "Online Shopping",
    description:
      "Offline shopping is old fashioned, we can help create your digital marketplace!",
    paragraph1:
      "Keep your products and services up to date with our web solutions. We help you focus on your merchandise by making the online experience easy for you.",
    paragraph2:
      "All is good when your customers are happy. The user interfaces we design are meant to ensure an easy and effortless experience while looking stunning!",
    mockup: onlineshopping,
    prego: onlineShoppingPrego,
  },
  {
    icon: cardholder,
    title: "Management System",
    description:
      "Managing a bussiness on paper is bad for the environment, why not go online?",
    paragraph1:
      "The entirety of your bussines, at your fingertips. Keep an eye on all of your company’s data and allow your staff to reach its potential with a modern dashboard and precise analytics on anything you’d like.",
    paragraph2:
      "Modular, to the tinniest button. We build flexible applications, able to grow at the same pace with your bussines to ensure an up-to-date experience for you and your team.",
    mockup: managementsystem,
    prego: managementSystemPrego,
  },
  {
    icon: entertainment,
    title: "Entertainment App",
    description:
      "Sharing your content with a large audience has never been so easy!",
    paragraph1:
      "You create impressive content while we make sure people can enjoy it. Let us help you in your quest of sharing your work with the world.",
    paragraph2:
      "Snappy and clean, our solution allows your audience to focus on what matters: the content you make for them.",
    mockup: entertainmentapp,
    prego: entertainmentAppPrego,
  },
  {
    icon: lightbulb,
    title: "Landing page",
    description:
      "Create your own digital identity and turn your vision into reality",
    paragraph1:
      "Allow potential clients, partners or even investors to reach you easier with a beautiful landing page, designed from scratch to represent you at your finest.",
    paragraph2:
      "We put your identity high on the list, carefully integrating SEO techniques to make sure that finding your page is quick and easy.",
    mockup: landingpage,
    prego: landingPagePrego,
  },
];
