//PACKS
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

//COMPONENTS
import CarouselButtons from "./CarouselButtons";

//STYLES
import "../styles/carouselMulti.scss";

const swipeNumber = 1;
const swipeWidth = 367.5;

export default function CarouselMulti(props) {
  //STATES
  const [position, setPosition] = useState(0);
  const [cards, setCards] = useState(null);

  //ON PROP CHANGE
  useEffect(() => {
    setCards(props.cards);
  }, [props]);

  //HANDLE BUTTON CLICKS
  function handleButtons(direction) {
    let nextPos = position + direction * swipeWidth * swipeNumber;
    if (
      nextPos < (cards.length - 4) * swipeWidth &&
      nextPos >= (-cards.length + 3) * swipeWidth
    ) {
      setPosition(nextPos);
    }
  }

  return (
    <div className="carouselMultiContainer">
      <CarouselButtons handleButtons={handleButtons.bind(this)} />
      <motion.div className="carouselMulti" animate={{ x: position }}>
        {cards}
      </motion.div>
      <div className="carouselMultiShades" />
    </div>
  );
}
