//PACKS
import React, { useState, useEffect } from "react";

//COMPONENTS
import Carousel from "./Carousel";
import ServicesModal from "./ServicesModal";
import PortfolioCard from "./PortfolioCard";

//STYLES
import "../styles/mobilePortfolio.scss";

//ASSETS
import { portfolioList } from "../assets/json/portfolioList";

export default function MobileServices(props) {
  //STATES
  const [cards, setCards] = useState([]);
  const [width, setWidth] = useState(0);
  const [modal, setModal] = useState(null);

  //ON MOUNT
  useEffect(() => {
    let tempCards = [];
    portfolioList.forEach((service, index) => {
      tempCards.push(
        <PortfolioCard
          service={service}
          handleModal={handleModal.bind(this)}
          key={index}
        />
      );
    });
    setCards(tempCards);
  }, []);

  //ON PROPS CHANGE
  useEffect(() => {
    setWidth(props.width);
  }, [props]);

  function handleModal(service) {
    setModal(service);
  }

  return (
    <div className="mobilePortfolioContainer">
      <div style={{ position: "absolute", top: "-61px" }} id="portfolio" />
      {modal ? (
        <ServicesModal handleModal={handleModal.bind(this)} service={modal} />
      ) : (
        <></>
      )}
      <div className="mobilePortfolioTitle fullwidth">
        <p>We take pride</p>
        <p>in our work</p>
      </div>
      <div className="mobilePortfolioSubtitle fullwidth">
        <p>Yours could be next</p>
      </div>
      <Carousel cards={cards} swipeLength={width} cardHeight={500} />
    </div>
  );
}
