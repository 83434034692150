import React, { useState, useEffect } from "react";
import "../styles/pregoPredefined.scss";
import { AnimatePresence, motion } from "framer-motion";

export default function PregoPredefined(props) {
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    function handleVisible(value) {
      setVisible(value);
    }
    handleVisible(true);
    return () => {
      handleVisible(false);
    };
  }, [props]);
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key={props.data.text + Math.random()}
          initial={{ opacity: 0, y: -50, position: "absolute" }}
          animate={{ opacity: 1, y: 0, position: "relative" }}
          exit={{ opacity: 0, y: 50, position: "absolute" }}
          className="pregoPredefinedContainer"
          style={props.style}
          onClick={() => {
            props.handleAnswer(props.data.next, {
              a: props.data.text,
              q: props.q,
            });
          }}
        >
          {props.data.text}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
