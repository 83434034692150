//PACKS
import React, { useEffect, useState } from "react";

//COMPONENTS
import AboutBox from "./AboutBox";

//STYLES
import "../styles/mobileAbout.scss";

//ASSETS
import { aboutList } from "../assets/json/aboutList";

export default function MobileAbout() {
  //STATES
  const [colOne, setColOne] = useState(null);
  const [colTwo, setColTwo] = useState(null);

  //ON MOUNT
  useEffect(() => {
    setColOne(aboutList.slice(0, 3));
    setColTwo(aboutList.slice(3, 6));
  }, []);

  return (
    <div className="mobileAboutContainer">
      <div style={{ position: "absolute", top: "-61px" }} id="about" />
      <div className="mobileAboutTitle fullwidth">
        <p>Software tailored</p>
        <p>to suit you</p>
      </div>
      <div className="mobileAboutSubtitle fullwidth">
        <p>We design applications to</p>
        <p>perfectly fit your workflow</p>
      </div>
      <div className="mobileAboutGrid fullwidth">
        <div className="mobileAboutCol">
          {colOne &&
            colOne.map((item, index) => (
              <AboutBox device="mobile" key={index} item={item} />
            ))}
        </div>
        <div className="mobileAboutCol">
          {colTwo &&
            colTwo.map((item, index) => (
              <AboutBox device="mobile" key={index} item={item} />
            ))}
        </div>
      </div>
    </div>
  );
}
