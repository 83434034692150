import React, { useState, useEffect, useRef } from "react";
import "../styles/pregoInput.scss";
import { AnimatePresence, motion } from "framer-motion";
import caretRight from "../assets/icons/caretRight.svg";
export default function PregoInput(props) {
  const [input, setInput] = useState("");
  const inputRef = useRef("");
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    function handleVisible(value) {
      setVisible(value);
    }
    handleVisible(true);
    return () => {
      handleVisible(false);
    };
  }, [props]);
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key={props.data.text + props.data.next}
          initial={{ opacity: 0, y: -50, position: "absolute" }}
          animate={{ opacity: 1, y: 0, position: "relative" }}
          exit={{ opacity: 0, y: 50, position: "absolute" }}
          className="pregoInputContainer"
          style={props.style}
        >
          <input
            ref={inputRef}
            placeholder={props.data.text}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            style={{ width: props.style ? props.style.input - 125 + "px" : "" }}
          />
          <button
            onClick={() => {
              props.handleAnswer(props.data.next, {
                a: input,
                q: props.q,
              });

              // inputRef.current.value = "";
            }}
            style={{ width: "75px" }}
          >
            <img src={caretRight} alt="btn arrow" />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
