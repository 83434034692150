//PACKS
import React, { useState, useEffect } from "react";

//COMPONENTS
import MobileNavbar from "./MobileNavbar";
import MobileHero from "./MobileHero";
import MobileAbout from "./MobileAbout";
import MobileServices from "./MobileServices";
import MobilePortfolio from "./MobilePortfolio";
import MobileContact from "./MobileContact";
import MobilePrego from "./MobilePrego";

//STYLES
import "../styles/mobile.scss";

export default function Mobile(props) {
  //STATES
  const [width, setWidth] = useState(0);

  //ON PROP CHANGE
  useEffect(() => {
    setWidth(props.width);
  }, [props]);

  return (
    <div className="mobileContainer">
      <MobileNavbar />
      <div className="mobileNavWhitespace" />
      <MobileHero />
      <MobileAbout />
      <MobileServices width={width} />
      <MobilePortfolio width={width} />
      <MobilePrego />
      <MobileContact />
    </div>
  );
}
