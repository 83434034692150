//PACKS
import React, { useState, useEffect } from "react";

//COMPONENTS
import CarouselBleeding from "./CarouselBleeding";
import ServicesModal from "./ServicesModal";
import PortfolioCardTablet from "./PortfolioCardTablet";

//STYLES
import "../styles/tabletPortfolio.scss";

//ASSETS
import { portfolioList } from "../assets/json/portfolioList";

export default function TabletPortfolio(props) {
  //STATES
  const [cards, setCards] = useState([]);
  const [width, setWidth] = useState(0);
  const [modal, setModal] = useState(null);

  //ON MOUNT
  useEffect(() => {
    let tempCards = [];
    portfolioList.forEach((service, index) => {
      tempCards.push(
        <PortfolioCardTablet
          service={service}
          handleModal={handleModal.bind(this)}
          key={index}
        />
        // <div
        //   style={{
        //     backgroundColor: "black",
        //     height: "400px",
        //     width: "634px",
        //     margin: "0 calc((100vw - 634px)/8)",
        //   }}
        // ></div>
      );
    });
    setCards(tempCards);
  }, []);

  //ON PROPS CHANGE
  useEffect(() => {
    setWidth(props.width);
  }, [props]);

  function handleModal(service) {
    setModal(service);
  }

  return (
    <div className="tabletPortfolioContainer">
      <div style={{ position: "absolute", top: "-113px" }} id="portfolio" />
      {modal ? (
        <ServicesModal handleModal={handleModal.bind(this)} service={modal} />
      ) : (
        <></>
      )}
      <div className="tabletPortfolioTitle fullwidth">
        <p>We take pride in our work</p>
      </div>
      <div className="tabletPortfolioSubtitle fullwidth">
        <p>Yours could be next</p>
      </div>
      <CarouselBleeding
        cards={cards}
        width={width}
        cardHeight={400}
        cardWidth={634 + (width - 634) / 4}
      />
    </div>
  );
}
